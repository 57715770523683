var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Matchlist:" }, [
    _c("div", { staticClass: "iframe-container" }, [
      _c("iframe", {
        attrs: {
          src: _vm.src,
          frameborder: "0",
          allow: "encrypted-media",
          sandbox: "allow-same-origin allow-scripts allow-forms"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }