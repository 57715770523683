<template>
  <div class="Matchlist:">
    <div class="iframe-container">
      <iframe
        :src="src"
        frameborder="0"
        allow="encrypted-media"
        sandbox="allow-same-origin allow-scripts allow-forms"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "examples",
  data() {
    return {
      example1Form: {
        src: "https://www.pexels.com/search",
        searchTerm: "tiger",
      },
      src: process.env.VUE_APP_BI_LINK,
      iframeLoading: true,
    };
  },
  methods: {
    onLoad() {
      console.log("iframe loaded");
      this.iframeLoading = false;
    },
    onIframeLoad() {
      console.log("iframe loaded");
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  mounted() {},
  created() {
    if (this.msg == undefined || this.msg == "") {
      // Se loggato verifico il token
      if (this.loggedIn()) {
        this.src =
          process.env.VUE_APP_BI_LINK +
          "/login?user=" +
          localStorage.getItem("useremail") +
          "&token=" +
          localStorage.getItem("access_token") +
          "&next=" +
          process.env.VUE_APP_BI_LINK +
          "/superset/dashboard/5/?standalone=true";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.Matchlist\: {
  padding: 40px;
  &container {
    display: flex;
  }
  &box {
    color: white;
    padding: 15px;
    // background-image: url("~@/assets/images/general/sfondo.jpg");
    position: relative;
    &-wrap {
      border: var(--border);
      padding: 25px;
      overflow-y: auto;
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  &container {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
    overflow-y: auto;
  }
  &container40 {
    width: 80%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    overflow-x: scroll;
  }
  &container60 {
    width: 50%;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
    flex-direction: column;
  }
  &single {
    width: (100%/3);
    padding: 20px;
    &_val {
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 30px;
    }
  }
}
</style>
